import React from 'react';

import { ReactComponent as CloudSvg } from '../../../../assets/cloud.svg';
import { ReactComponent as CloudOpacitySvg } from '../../../../assets/cloud-opacity.svg';

import styles from './Clouds.module.css';

const Clouds = () => (
  <div className={styles.wrapperClouds}>
    <CloudSvg className={styles.cloudAbsolute} />

    <CloudOpacitySvg className={styles.cloud} />
  </div>
);

export default Clouds;
