import { useZustandAppStateStore } from './store/appState/store';
import {
  getAppStateSelector,
  setAppStateAction,
} from './store/appState/selectors';
import { EAppState } from './store/appState/types';
import LoadingScreen from './pages/LoadingScreen';
import GameScreen from './pages/GameScreen';
import { Socket, io } from 'socket.io-client';
import { useEffect, useRef, useState } from 'react';
import {
  getGameStakeSelector,
  getGameStateSelector,
  setGameStateAction,
  setSlotResultAction,
} from './store/game/selectors';

import { EGameStatus, SlotResult } from './store/game/types';
import { useZustandGameStateStore } from './store/game/store';
import Modal from './pages/Modal';

function App() {
  const appState = useZustandAppStateStore(getAppStateSelector);

  const setAppState = useZustandAppStateStore(setAppStateAction);

  const [clientInfo, setClientInfo] = useState<null | { accessToken: string }>(
    null
  );

  const socket = useRef<null | Socket>(null);

  const gameStatus = useZustandGameStateStore(getGameStateSelector);

  const setGameState = useZustandGameStateStore(setGameStateAction);
  const setSlotResult = useZustandGameStateStore(setSlotResultAction);

  const stake = useZustandGameStateStore(getGameStakeSelector);

  const callback = (event: any) => {
    try {
      const { data } = event;
      const { type, payload } = data;

      switch (type) {
        case 'clientInfo':
          setClientInfo(payload);
          break;

        default:
          break;
      }
    } catch (error) {
      console.log('🚀 ~ callback ~ error:', error);
    }
  };

  const sendCommand = (data: { stake: number }) => {
    socket.current?.emit('client-tokyo-slot-spin', data);
  };

  useEffect(() => {
    window.addEventListener('message', callback, true);

    window?.parent?.postMessage({ type: 'in-house-game' }, '*');

    return () => {
      window.removeEventListener('message', callback, true);
    };
  }, []);

  useEffect(() => {
    if (!clientInfo) {
      return () => {
        socket.current?.disconnect();
        socket.current = null;
      };
    }

    console.info(process.env.REACT_APP_URL, 'SOCKET URL');
    console.info(clientInfo, 'clientInfo');

    socket.current = io(process.env.REACT_APP_URL as string);

    socket.current.on('connect', () => {
      socket.current?.emit('authorize-client', { clientInfo });
    });

    socket.current.on('verified', () => {
      console.info('verified');
      setAppState(EAppState.GameScreen);
    });

    socket.current.on('bet-result', (result: SlotResult) => {
      setGameState(EGameStatus.Result);
      setSlotResult(result);
    });

    return () => {
      socket.current?.disconnect();
      socket.current = null;
    };
  }, [clientInfo, setAppState, setGameState, setSlotResult]);

  useEffect(() => {
    if (!socket.current || gameStatus !== EGameStatus.Run) {
      return;
    }

    sendCommand({ stake });
  }, [gameStatus, stake]);

  return (
    <div>
      {appState === EAppState.LoaderScreen && <LoadingScreen />}

      {appState === EAppState.GameScreen && <GameScreen />}

      <Modal />
    </div>
  );
}

export default App;
