import { ZustandGameStateStore } from './types';

export const getGameStateSelector = (store: ZustandGameStateStore) =>
  store.gameStatus;
export const getGameStakeSelector = (store: ZustandGameStateStore) =>
  store.stake;
export const getGameResultSelector = (store: ZustandGameStateStore) =>
  store.result;

// Actions

export const setGameStateAction = (store: ZustandGameStateStore) =>
  store.setGameStatus;

export const setGameStakeAction = (store: ZustandGameStateStore) =>
  store.setGameStake;
export const setSlotResultAction = (store: ZustandGameStateStore) =>
  store.setSlotResult;
