import Clouds from './components/Clouds';
import Header from './components/Header';
import SlotMachine from './components/SlotMachine';
import StakeController from './components/StakeController';
import PlayButton from './components/PlayButton';

import styles from './GameScreen.module.css';
import Jackpot from './components/Jackpot';

const GameScreen = () => (
  <div className={styles.wrapperGameWrapper}>
    <div>
      <Header />

      <SlotMachine />

      <StakeController />
    </div>
    <PlayButton />

    <Clouds />

    <Jackpot />
  </div>
);

export default GameScreen;
