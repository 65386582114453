import { create } from 'zustand';
import { EGameStatus, ZustandGameStateStore } from './types';

export const useZustandGameStateStore = create<ZustandGameStateStore>(
  (set, get) => ({
    gameStatus: EGameStatus.Idle,
    stake: 100,
    result: null,

    setGameStatus: (gameStatus) => {
      set({ gameStatus });
    },
    setSlotResult: (result) => {
      set({ result });
    },
    setGameStake: (stake) => {
      set({ stake });
    },
  })
);
