import { ReactComponent as SevenSvg } from '../../../../assets/7.svg';
import { ReactComponent as CoinSmileSvg } from '../../../../assets/coin-smile.svg';
import { ReactComponent as CoinEthSvg } from '../../../../assets/coin-eth.svg';
import { ReactComponent as TicketSvg } from '../../../../assets/ticket.svg';
import { ReactComponent as QuestionSvg } from '../../../../assets/question.svg';
import { ReactComponent as HeardSvg } from '../../../../assets/heard.svg';

export const LAST = CoinSmileSvg;

export const BASE = [
  LAST,

  SevenSvg,

  CoinEthSvg,

  TicketSvg,

  QuestionSvg,

  HeardSvg,
];

export const WIN = SevenSvg;

export const BASE_COLUMN_STATE = {
  column: BASE,
  isAnimated: false,
  isWin: false,
};

export const BASE_STATE = {
  one: BASE_COLUMN_STATE,
  two: BASE_COLUMN_STATE,
  three: BASE_COLUMN_STATE,
  fours: BASE_COLUMN_STATE,
};
