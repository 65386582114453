import { create } from 'zustand';
import { EAppState, ZustandAppStateStore } from './types';

export const useZustandAppStateStore = create<ZustandAppStateStore>(
  (set, get) => ({
    appState: EAppState.Loading,

    setAppState: (appState) => {
      set({ appState });
    },
  })
);
