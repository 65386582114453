export interface ZustandModalStore extends ZustandModalEntities {
  setModal: (m: EModalTypes, prize: number) => void;
}

interface ZustandModalEntities {
  modalType: EModalTypes;
  prize: number;
}

export const enum EModalTypes {
  Unknown,
  BigWin,
}
