import { useCallback, useEffect, useState } from 'react';
import cln from 'classnames';

import slotMachine from '../../../../assets/slot-game.png';

import { useZustandGameStateStore } from '../../../../store/game/store';
import {
  getGameResultSelector,
  getGameStakeSelector,
  getGameStateSelector,
  setGameStateAction,
  setSlotResultAction,
} from '../../../../store/game/selectors';
import { EGameStatus } from '../../../../store/game/types';
import { getRandomInt, sleep } from '../../../../utils/shared';
import { setModalAction } from '../../../../store/modal/selectors';
import { EModalTypes } from '../../../../store/modal/types';
import { useZustandModalStore } from '../../../../store/modal/store';

import { BASE, BASE_STATE, LAST, WIN } from './constants';

import styles from './SlotMachine.module.css';

const SlotMachine = () => {
  const gameState = useZustandGameStateStore(getGameStateSelector);

  const stake = useZustandGameStateStore(getGameStakeSelector);

  const setGameState = useZustandGameStateStore(setGameStateAction);

  const setGameResult = useZustandGameStateStore(setSlotResultAction);

  const gameResult = useZustandGameStateStore(getGameResultSelector);

  const [columns, setColumns] = useState<any>({ ...BASE_STATE });

  const [isRunAnimation, setAnimationStatus] = useState(false);

  const [prize, setPrize] = useState(0);

  const setModal = useZustandModalStore(setModalAction);

  const runAnimation = useCallback(async () => {
    if (!gameResult || !isRunAnimation) {
      return;
    }

    const { prize } = gameResult;
    const isBigWin = prize > stake * 5;
    const isWin = prize > stake / 2;

    setPrize(0);

    setGameResult(null);
    setAnimationStatus(false);

    const { isOneWin, isTwoWin, isThreeWin, isFourthWin } = gameResult;

    const resultOne = isOneWin ? WIN : BASE[getRandomInt(2, 5)];

    const one = {
      column: [...columns.one.column, resultOne, LAST],
      isAnimated: true,
      isWin: isOneWin,
    };

    const twoResult = isTwoWin ? WIN : BASE[getRandomInt(2, 5)];

    const two = {
      column: [...columns.two.column, twoResult, LAST],
      isAnimated: true,
      isWin: isTwoWin,
    };

    const threeResult = isThreeWin ? WIN : BASE[getRandomInt(2, 5)];

    const three = {
      column: [...columns.three.column, threeResult, LAST],
      isAnimated: true,
      isWin: isThreeWin,
    };

    const fourResult = isFourthWin ? WIN : BASE[getRandomInt(2, 5)];

    const fours = {
      column: [...columns.fours.column, fourResult, LAST],
      isAnimated: true,
      isWin: isFourthWin,
    };

    setColumns({
      one,
      two,
      three,
      fours,
    });

    await sleep(1200);

    setPrize(prize);
    setColumns({
      one: {
        column: [...one.column.slice(-3), LAST, LAST, LAST],
        isAnimated: false,
        isWin: false,
      },
      two: {
        column: [...two.column.slice(-3), LAST, LAST, LAST],
        isAnimated: false,
        isWin: false,
      },
      three: {
        column: [...three.column.slice(-3), LAST, LAST, LAST],
        isAnimated: false,
        isWin: false,
      },
      fours: {
        column: [...fours.column.slice(-3), LAST, LAST, LAST],
        isAnimated: false,
        isWin: false,
      },
    });

    if (isBigWin) {
      setModal(EModalTypes.BigWin, prize);

      return;
    }

    if (isWin) {
      console.info('WIN', prize);
    }

    setGameState(EGameStatus.Idle);
  }, [columns, gameResult, isRunAnimation, setGameResult, setGameState, stake]);

  useEffect(() => {
    if (gameState !== EGameStatus.Result) {
      return;
    }

    setAnimationStatus(true);
  }, [gameState]);

  useEffect(() => {
    if (!isRunAnimation) {
      return;
    }
    runAnimation();
  }, [isRunAnimation, runAnimation]);

  return (
    <div className={styles.wrapperSlotMachine}>
      <div className={styles.itemWrapper}>
        {Object.keys(columns).map((name) => {
          const { column, isAnimated } = columns[name] as any;

          return (
            <div
              className={cln(styles.item, {
                [styles.spin]: isAnimated,
              })}
              key={`${name}`}
              data-name={name}
            >
              {/* @ts-ignore */}
              {column.map((Icon, inx) => (
                <Icon key={`${inx}_${name}`} data-key={`${inx}_${name}`} />
              ))}
            </div>
          );
        })}
      </div>

      <img src={slotMachine} alt="slot machine" className={styles.img} />

      {!!prize && <div className={styles.winSum}>Win Sum:{prize}</div>}
    </div>
  );
};

export default SlotMachine;
