import { ZustandModalStore } from './types';

export const getModalTypeSelector = (store: ZustandModalStore) =>
  store.modalType;
export const getModalPrizeTypeSelector = (store: ZustandModalStore) =>
  store.prize;

// Actions

export const setModalAction = (store: ZustandModalStore) => store.setModal;
