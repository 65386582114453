import React, { useState } from 'react';
import cln from 'classnames';

import { ReactComponent as SmallBtnSvg } from '../../../../assets/small-btn.svg';
import { ReactComponent as StakeSvg } from '../../../../assets/stake.svg';
import { ReactComponent as TetherSvg } from '../../../../assets/tether.svg';

import { useZustandGameStateStore } from '../../../../store/game/store';
import {
  getGameStakeSelector,
  setGameStakeAction,
} from '../../../../store/game/selectors';

import styles from './StakeController.module.css';

const StakeController = () => {
  const stake = useZustandGameStateStore(getGameStakeSelector);

  const setStake = useZustandGameStateStore(setGameStakeAction);

  const [animation, setAnimation] = useState<{ plus: boolean; minus: boolean }>(
    {
      plus: false,
      minus: false,
    }
  );

  const handleClick = (isPlus: boolean) => () => {
    setAnimation({
      ...animation,
      [isPlus ? 'plus' : 'minus']: true,
    });

    if (stake < 1 && !isPlus) {
      return;
    }

    if (stake === 100 && isPlus) {
      return;
    }

    const newStake = isPlus ? stake + 1 : stake - 1;

    setStake(newStake);

    setTimeout(() => {
      setAnimation({
        plus: false,
        minus: false,
      });
    }, 100);
  };

  return (
    <div className={styles.wrapperStakeController}>
      <div
        className={cln(styles.smallButton, {
          [styles.pressAnimation]: animation.plus,
        })}
        onClick={handleClick(true)}
      >
        <div className={styles.symbol}>+</div>
        <SmallBtnSvg />
      </div>

      <div className={styles.wrapperStake}>
        <div className={styles.content}>
          <TetherSvg />
          <div className={styles.stake}>{stake}.00</div>
        </div>

        <StakeSvg className={styles.bg} />
      </div>

      <div
        className={cln(styles.smallButton, {
          [styles.pressAnimation]: animation.minus,
        })}
        onClick={handleClick(false)}
      >
        <div className={styles.symbol}>-</div>
        <SmallBtnSvg />
      </div>
    </div>
  );
};

export default StakeController;
