import { useEffect } from 'react';
import cln from 'classnames';

import { ReactComponent as ModalLinesSvg } from '../../assets/modal-lines.svg';
import { ReactComponent as WinPopUpSvg } from '../../assets/win-popup.svg';
import { ReactComponent as TetherSvg } from '../../assets/tether.svg';

import { useZustandModalStore } from '../../store/modal/store';
import {
  getModalPrizeTypeSelector,
  getModalTypeSelector,
  setModalAction,
} from '../../store/modal/selectors';
import { EModalTypes } from '../../store/modal/types';

import { setGameStateAction } from '../../store/game/selectors';
import { useZustandGameStateStore } from '../../store/game/store';
import { EGameStatus } from '../../store/game/types';

import styles from './Modal.module.css';

const audioBigWin = new Audio('/sounds/big-win.wav');

audioBigWin.load();

const Modal = () => {
  const modal = useZustandModalStore(getModalTypeSelector);

  const prize = useZustandModalStore(getModalPrizeTypeSelector);

  const setModal = useZustandModalStore(setModalAction);

  const setGameState = useZustandGameStateStore(setGameStateAction);

  useEffect(() => {
    if (modal === EModalTypes.Unknown) {
      return;
    }
    audioBigWin?.play();

    setTimeout(() => {
      setModal(EModalTypes.Unknown, 0);
      setGameState(EGameStatus.Idle);
    }, 2000);
  }, [modal]);

  if (modal === EModalTypes.Unknown) {
    return null;
  }

  return (
    <div
      className={cln(styles.wrapperModal, {
        [styles.show]: true,
      })}
    >
      <ModalLinesSvg className={styles.lines} />

      <div className={styles.content}>
        <div className={styles.prize}>
          <TetherSvg />

          <div className={styles.sum}>{prize}</div>
        </div>

        <WinPopUpSvg className={styles.bg} />
      </div>
    </div>
  );
};

export default Modal;
