import { create } from 'zustand';
import { EModalTypes, ZustandModalStore } from './types';

export const useZustandModalStore = create<ZustandModalStore>((set) => ({
  modalType: EModalTypes.Unknown,
  prize: 0,

  setModal: (modalType, prize) => {
    set({ modalType, prize });
  },
}));
