import { ReactComponent as LightSvg } from '../../assets/ligths-hafl.svg';
import { ReactComponent as SlotSvg } from '../../assets/slot.svg';
import { ReactComponent as CoinEthSvg } from '../../assets/coin-eth.svg';
import { ReactComponent as CoinSmileSvg } from '../../assets/coin-smile.svg';
import { ReactComponent as HeardSvg } from '../../assets/heard.svg';
import { ReactComponent as TicketSvg } from '../../assets/ticket.svg';
import { ReactComponent as LogoSvg } from '../../assets/logo.svg';

import styles from './LoadingScreen.module.css';
import { useZustandAppStateStore } from '../../store/appState/store';
import { setAppStateAction } from '../../store/appState/selectors';
import { EAppState } from '../../store/appState/types';

const LoadingScreen = () => {
  const setAppState = useZustandAppStateStore(setAppStateAction);

  const handleChangeState = () => {
    setAppState(EAppState.GameScreen);
  };

  return (
    <div className={styles.wrapperLoadingScreen}>
      <SlotSvg className={styles.topLeft} />

      <CoinEthSvg className={styles.topRight} />

      <LogoSvg className={styles.logo} onClick={handleChangeState} />

      <CoinSmileSvg className={styles.bottomRight} />

      <HeardSvg className={styles.bottomLeft} />
      <TicketSvg className={styles.bottomCenter} />

      <LightSvg className={styles.light} />
    </div>
  );
};

export default LoadingScreen;
