import React from 'react';

import { ReactComponent as NoPlaySvg } from '../../../../assets/btn-no-play.svg';
import { ReactComponent as PlaySvg } from '../../../../assets/btn-play.svg';

import styles from './PlayButton.module.css';
import { useZustandGameStateStore } from '../../../../store/game/store';
import {
  getGameStateSelector,
  setGameStateAction,
} from '../../../../store/game/selectors';
import { EGameStatus } from '../../../../store/game/types';

const audioTakeSeven = new Audio('/sounds/slot-7.wav');

const PlayButton = () => {
  const gameState = useZustandGameStateStore(getGameStateSelector);

  const setGameStatus = useZustandGameStateStore(setGameStateAction);

  const isPlaying = gameState !== EGameStatus.Idle;

  const Button = isPlaying ? PlaySvg : NoPlaySvg;

  const handleSetRunState = () => {
    if (gameState !== EGameStatus.Idle) {
      return;
    }
    audioTakeSeven?.play();
    setGameStatus(EGameStatus.Run);
  };

  return (
    <div className={styles.wrapperPlayButton} onClick={handleSetRunState}>
      <Button />
    </div>
  );
};

export default PlayButton;
