import React from 'react';

import { ReactComponent as HeaderSvg } from '../../../../assets/header.svg';
import { ReactComponent as QuestionSvg } from '../../../../assets/question.svg';
import gameLabel from '../../../../assets/gameLabel.png';

import styles from './Header.module.css';

const Header = () => (
  <div className={styles.wrapperHeaderGame}>
    <QuestionSvg className={styles.questionButton} />

    <HeaderSvg className={styles.headerBg} />

    <img src={gameLabel} alt="game label" className={styles.gameLabel} />
  </div>
);

export default Header;
