export interface ZustandAppStateStore extends ZustandAppStateEntities {
  setAppState: (a: EAppState) => void;
}

interface ZustandAppStateEntities {
  appState: EAppState;
}

export const enum EAppState {
  Loading,
  LoaderScreen,
  GameScreen,
}
