import React from 'react';

import { ReactComponent as JackpotTextSvg } from '../../../../assets/jackpot.svg';
import { ReactComponent as JackpotBgSvg } from '../../../../assets/jackpot-bg.svg';
import { ReactComponent as CoinSvg } from '../../../../assets/coin-eth.svg';

import styles from './Jackpot.module.css';

const Jackpot = () => (
  <div className={styles.wrapperJackpot}>
    <JackpotTextSvg className={styles.text} />

    <div className={styles.wrapperContent}>
      <CoinSvg />

      <div className={styles.sum}>999.999</div>
    </div>

    <JackpotBgSvg className={styles.bg} />
  </div>
);

export default Jackpot;
