export interface ZustandGameStateStore extends ZustandGameStateEntities {
  setGameStatus: (a: EGameStatus) => void;
  setGameStake: (a: number) => void;
  setSlotResult: (a: SlotResult | null) => void;
}

interface ZustandGameStateEntities {
  gameStatus: EGameStatus;
  stake: number;
  result: null | SlotResult;
}

export const enum EGameStatus {
  Idle,
  Run,
  Result,
}

export type SlotResult = {
  isOneWin: boolean;
  isTwoWin: boolean;
  isThreeWin: boolean;
  isFourthWin: boolean;
  prize: number;
};
